import CardImage from "../../assets/svg/listOfCards.svg";
import CardCVVImage from "../../assets/svg/card-cvv.svg";
import { IPaymentDetails } from "../../types/Purchase";
import { useState } from "react";

interface IProps {
  type: string;
  details: IPaymentDetails;
  setPaymentDetails: (e: any) => void;
}

function PaymentMethodDetails({ type, details, setPaymentDetails }: IProps) {
  const [cardType, setCardType] = useState("Debit Card");
  const renderCardDetails = () => {
    const handleChange = (e: any) => {
      const { value, name } = e.target;
      name === "cardType" && setCardType(value);
      setPaymentDetails((prev: any) => ({ ...prev, [name]: value }));
    };
    return (
      <div>
        <p className="font-bold mb-4 text-lg">Enter card details</p>
        <div className="mb-4">
          <img src={CardImage} alt="cardList" />
          <span className="text-xs text-gray-400">
            Accepted credit and debit card types
          </span>
          <div className="flex w-full">
            <div className="w-24">
              <p className="text-sm mb-2 font-semibold">Card type</p>
              <div>
                <select
                  className="w-full border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
                  onChange={handleChange}
                  name="cardType"
                  value={cardType}
                >
                  <option value="Debit Card">Debit Card</option>
                  <option value="Credit Card">Credit Card</option>
                </select>
              </div>
            </div>
            <div className="basis-full pr-20">
              <p className="text-sm mb-2 font-semibold">Card number</p>
              <input
                className="w-1/2 pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1 mb-2"
                value={details.cardNumber}
                name="cardNumber"
                maxLength={16}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <p className="text-sm mb-2 font-semibold">Name on the card</p>
          <input
            className="w-1/2 pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1 mb-2"
            value={details.cardName}
            name="cardName"
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <p className="text-sm font-semibold">Expiry date</p>
          <p className="text-xs text-gray-400 mb-2 font-normal">
            For example, 10/20
          </p>
          <div className="flex items-center gap-5">
            <div className="w-[5rem]">
              <p className="text-sm">Month</p>
              <input
                className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1 mb-2"
                value={details.expiryMonth}
                name="expiryMonth"
                maxLength={2}
                onChange={handleChange}
              />
            </div>
            <p>/</p>
            <div className="w-[5rem]">
              <p className="text-sm ">Year</p>
              <input
                className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1 mb-2"
                value={details.expiryYear}
                name="expiryYear"
                maxLength={4}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="mb-4">
          <p className="text-sm font-semibold">Card security code</p>
          <p className="text-xs text-gray-400 mb-2">
            The last 3 digits on the back of the card
          </p>
          <div className="w-full flex items-center">
            <input
              className="w-1/4 pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
              value={details.securityCode}
              name="securityCode"
              onChange={handleChange}
              maxLength={3}
            />
            <img src={CardCVVImage} alt="cvv" className="w-[4rem] h-[2rem]" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="py-4">{type === "card" ? renderCardDetails() : null}</div>
  );
}

export default PaymentMethodDetails;
