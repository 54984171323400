import axios from "axios";
import { SUBMIT_SMART_CARD_DATA } from "./endpoints";
import { smartCardSubmitDataType } from "../types/smartCardSubmit";

type fetchPlanProps = {
  payload: smartCardSubmitDataType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
export const smartCardSubmitData = async ({
  payload,
  setLoading,
}: fetchPlanProps) => {
  setLoading(true);
  try {
    const response = await axios.post(SUBMIT_SMART_CARD_DATA, payload);
    console.log(response);
  } catch (error) {
    console.error("Card Submission Failed!", error);
  } finally {
    setLoading(false);
  }
};
