import React from "react";

interface IReviewInputProps {
  label?: string;
  value: string;
}
const ReviewInput = (props: IReviewInputProps) => {
  const { label, value } = props;
  return (
    <div className="w-full py-2 px-4 flex items-center gap-2 rounded-sm bg-green-green2">
      <span className="text-sm text-green-green3 font-light">{label}</span>
      <span className="text-sm text-green-green3 font-semibold">{value}</span>
    </div>
  );
};

export default ReviewInput;
