import React, { useEffect, useState } from "react";
import CustomTitle from "./CustomTitle";
import { IFormValues, IPaymentDetails } from "../../types/Purchase";
import { FormikProps } from "formik";
import PaymentMethodDetails from "./PaymentMethodDetails";
import {
  verifyUserEmail,
  verifyUserPhone,
} from "../../services/checkExistingUser";
import InnerLoader from "../common/InnerLoader";
import approvedIcon from "../../assets/svg/approved-tic.svg";
import rejectedIcon from "../../assets/svg/rejected-cross.svg";
import stateNames from "../../utils/stateNames/stateNames.json";

interface IPaymentProps {
  paymentType: string;
  setPaymentType: (e: any) => void;
  paymentDetails: IPaymentDetails;
  setPaymentDetails: (e: any) => void;
  setValidEmailPhone: (e: any) => void;
}

const Payment = (props: IPaymentProps & FormikProps<IFormValues>) => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    touched,
    setTouched,
    paymentType,
    setPaymentType,
    paymentDetails,
    setPaymentDetails,
    setValidEmailPhone,
  } = props;

  const [emailLoader, setEmailLoader] = useState(false);
  const [phoneLoader, setPhoneLoader] = useState(false);
  const [emailExist, setEmailExist] = useState(null);
  const [phoneExist, setPhoneExist] = useState(null);

  const paymentTypeChange = (e: any) => {
    setPaymentType(e.target.value);
  };

  useEffect(() => {
    setValidEmailPhone(false);
  }, []);

  useEffect(() => {
    if (emailExist === false && phoneExist === false) {
      setValidEmailPhone(true);
    }
  }, [emailExist, phoneExist, values.email, values.phone]);

  useEffect(() => {
    if (values.phone.length) {
      handlePhoneVerify();
    }
    if (values.email.length) {
      handleEmailVerify();
    }
  }, []);

  const handlePhoneVerify = (e?: any) => {
    if (e) {
      handleBlur(e);
    }
    verifyUserPhone({
      setLoading: setPhoneLoader,
      payload: { phoneNumber: values.phone },
      setIsExist: setPhoneExist,
    });
  };

  const handleEmailVerify = (e?: any) => {
    if (e) {
      handleBlur(e);
    }
    verifyUserEmail({
      setLoading: setEmailLoader,
      payload: { email: values.email },
      setIsExist: setEmailExist,
    });
  };

  const renderStateNames = () => {
    if (stateNames && Object.keys(stateNames).length > 0) {
      return Object.values(stateNames).map((name) => {
        return <option value={name}>{name}</option>;
      });
    } else {
      return <></>;
    }
  };

  const verifyStatus = (type: string) => {
    if (type == "email") {
      if (!errors.email) {
        if (emailLoader) {
          return <InnerLoader />;
        } else {
          return emailExist === true ? (
            <img src={rejectedIcon} />
          ) : emailExist === false ? (
            <img src={approvedIcon} />
          ) : (
            phoneExist === null && null
          );
        }
      }
    }
    if (type == "phone") {
      if (!errors.phone) {
        if (phoneLoader) {
          return <InnerLoader />;
        } else {
          return phoneExist === true ? (
            <img src={rejectedIcon} />
          ) : phoneExist === false ? (
            <img src={approvedIcon} />
          ) : (
            phoneExist === null && null
          );
        }
      }
    }
  };

  useEffect(() => {
    setEmailExist(null);
  }, [values.email]);
  useEffect(() => {
    setPhoneExist(null);
  }, [values.phone]);

  useEffect(() => {
    setTouched({
      firstName: false,
      lastName: false,
      companyName: false,
      email: false,
      phone: false,
      address1: false,
      address2: false,
      city: false,
      state: false,
      zip: false,
      country: false,
    });
  }, []);

  return (
    <div>
      <CustomTitle className="font-Kumbh mb-2 font-bold" title="Information" />
      <div className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <input
              className={`w-full pl-4 ${
                errors.firstName && touched.firstName && "border-red-600"
              } border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1`}
              placeholder="First name"
              name={"firstName"}
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
            />

            {touched.firstName && errors.firstName && (
              <p className="text-[10px] text-red-700">{errors.firstName}</p>
            )}
          </div>

          <div>
            <input
              className={`w-full pl-4 border ${
                errors.lastName && touched.lastName && "border-red-600"
              }  rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1`}
              placeholder="Last name"
              name={"lastName"}
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
            />
            {touched.lastName && errors.lastName && (
              <p className="text-[10px] text-red-700">{errors.lastName}</p>
            )}
          </div>
        </div>
        <div>
          <input
            className="w-full pl-4 border  rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
            placeholder="Company name(optional)"
            name={"companyName"}
            onChange={handleChange}
            value={values.companyName}
          />
        </div>
        <div className="relative">
          <input
            type="email"
            className={`w-full pl-4 border  ${
              (errors.email || emailExist) &&
              touched.email &&
              !emailLoader &&
              "border-red-600"
            } rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1`}
            placeholder="Email address"
            name={"email"}
            onChange={handleChange}
            value={values.email}
            onBlur={(e) => handleEmailVerify(e)}
          />
          <div className="absolute top-[0.5rem] right-[0.5rem]">
            {verifyStatus("email")}
          </div>
          {touched.email && errors.email && (
            <p className="text-[10px] text-red-700">{errors.email}</p>
          )}
        </div>
        <div className="relative">
          <input
            className={`w-full relative  ${
              (errors.phone || phoneExist) &&
              touched.phone &&
              !phoneLoader &&
              "border-red-600"
            } pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1`}
            placeholder="Phone"
            name={"phone"}
            onChange={handleChange}
            value={values.phone}
            onBlur={(e) => {
              handlePhoneVerify(e);
            }}
          />
          <div className="absolute top-[0.5rem] right-[0.5rem]">
            {verifyStatus("phone")}
          </div>
          {touched.phone && errors.phone && (
            <p className="text-[10px] text-red-700">{errors.phone}</p>
          )}
        </div>
      </div>
      <CustomTitle
        className="font-Kumbh my-2 font-bold"
        title="Shipping address"
      />
      <div className="grid grid-cols-1 gap-4">
        <div>
          <input
            className={`w-full pl-4 border rounded-sm text-xs py-2 ${
              errors.address1 && touched.address1 && "border-red-600"
            } outline-none placeholder:text-green-green1 text-green-green1`}
            placeholder="Address"
            name={"address1"}
            onChange={handleChange}
            value={values.address1}
            onBlur={handleBlur}
          />
          {touched.address1 && errors.address1 && (
            <p className="text-[10px] text-red-700">{errors.address1}</p>
          )}
        </div>
        <div>
          <input
            className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
            placeholder="Apartment, suite, unit, etc. (optional)"
            name={"address2"}
            onChange={handleChange}
            value={values.address2}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="font-normal text-sm">City</label>
            <div>
              <input
                className={`w-full pl-4 border rounded-sm text-xs py-2 ${
                  errors.city && touched.city && "border-red-600"
                } outline-none placeholder:text-green-green1 text-green-green1`}
                //  className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
                placeholder="City name"
                name={"city"}
                onChange={handleChange}
                value={values.city}
                onBlur={handleBlur}
              />
              {touched.city && errors.city && (
                <p className="text-[10px] text-red-700">{errors.city}</p>
              )}
            </div>
          </div>
          <div>
            <label className="font-normal text-sm">State</label>
            <div>
              <select
                // className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
                className={`w-full pl-4 border rounded-sm text-xs py-2 ${
                  errors.state && touched.state && "border-red-600"
                } outline-none placeholder:text-green-green1 text-green-green1`}
                name={"state"}
                onChange={handleChange}
                value={values.state}
                onBlur={handleBlur}
              >
                <option value="">select any state</option>
                {renderStateNames()}
              </select>
              {touched.state && errors.state && (
                <p className="text-[10px] text-red-700">{errors.state}</p>
              )}
            </div>
          </div>
          <div>
            <label className="font-normal text-sm">ZIP</label>
            <div>
              <input
                className={`w-full pl-4 border rounded-sm text-xs py-2 ${
                  errors.zip && touched.zip && "border-red-600"
                } outline-none placeholder:text-green-green1 text-green-green1`}
                // className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
                placeholder="Zip code"
                name={"zip"}
                onChange={handleChange}
                value={values.zip}
                onBlur={handleBlur}
              />
              {touched.zip && errors.zip && (
                <p className="text-[10px] text-red-700">{errors.zip}</p>
              )}
            </div>
          </div>
        </div>
        <div>
          <label className="font-normal text-sm">Country</label>
          <div>
            <input
              className={`w-full pl-4 border rounded-sm text-xs py-2  ${
                touched.country && errors.country && "border-red-600"
              } outline-none placeholder:text-green-green1 text-green-green1 bg-slate-100`}
              // className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
              name={"country"}
              onChange={handleChange}
              value={values.country}
              onBlur={handleBlur}
              disabled
            />
            {touched.country && errors.country && (
              <p className="text-[10px] text-red-700">{errors.country}</p>
            )}
          </div>
        </div>
      </div>
      <CustomTitle className="font-Kumbh my-2 font-bold" title="Payment type" />
      <div className="p-4 w-full border rounded-sm">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <input
              type="radio"
              name="payment"
              id="card"
              value="card"
              checked
              onChange={paymentTypeChange}
            />
            <label
              htmlFor="card"
              className="font-bold text-xs text-green-green1"
            >
              Cards
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="radio"
              disabled
              name="payment"
              id="netBanking"
              value="netBanking"
              onChange={paymentTypeChange}
            />
            <label
              htmlFor="netBanking"
              className="font-bold text-xs text-green-green1"
            >
              Net Banking
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              disabled
              type="radio"
              name="payment"
              id="otherUpi"
              value="otherUPI"
              onChange={paymentTypeChange}
            />
            <label
              htmlFor="otherUpi"
              className="font-bold text-xs text-green-green1"
            >
              Other UPI Apps
            </label>
          </div>
        </div>
        <PaymentMethodDetails
          // touched={touched}
          // errors={errors}
          type={paymentType}
          details={paymentDetails}
          setPaymentDetails={setPaymentDetails}
        />
      </div>
    </div>
  );
};

export default Payment;
