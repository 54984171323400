import { Link } from "react-router-dom";

type ICustomLinkProps = {
  isHomeRoute: boolean;
  label: string;
  path: string;
};
const CustomLink = (props: ICustomLinkProps) => {
  const { isHomeRoute, path, label } = props;
  return isHomeRoute ? (
    <a href={`#${path}`} className="text-sm font-normal">
      {label}
    </a>
  ) : (
    <Link
      to={{ pathname: "/" }}
      state={{ path }}
      className="text-sm font-normal"
    >
      {label}
    </Link>
  );
};

export default CustomLink;
