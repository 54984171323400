import { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineMenu } from "react-icons/hi";
import { routePaths } from "../../utils/constants";
import CustomLink from "./CustomLink";
import IntouchLogo from "../../assets/icons/intouch-logo.svg";

const Navbar = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navItems1 = useMemo(
    () => [
      { label: "How InTouch works", path: `${routePaths.howItWorks}` },
      { label: "Features", path: `${routePaths.features}` },
      { label: "Enterprise", path: `${routePaths.teams}` },
      { label: "Cards", path: `${routePaths.card}` },
    ],
    []
  );
  const navItems2 = useMemo(() => ["Contact", "Help", "News"], []);
  const isHomeRoute = useMemo(() => pathname === "/", [pathname]);

  const handleClick = () => {
    window.open(process.env.REACT_APP_SIGNIN_ROUTING_LINK, "_blank");
  };

  return (
    <header className="w-full px-4 h-16 bg-black-black1 flex items-center sticky top-8 left-0 right-0 z-50 ">
      <nav className="w-full hidden lg:flex justify-between items-center">
        <ul className="flex gap-8 items-center text-white">
          <li className="mr-8">
            <Link className="" to="/">
              <img src={IntouchLogo} alt="" />
            </Link>
          </li>
          {navItems1.map((navItem) => (
            <CustomLink
              key={navItem.label}
              isHomeRoute={isHomeRoute}
              {...navItem}
            />
          ))}
          <li>
            <Link to="/Pricing" className="text-sm font-normal">
              Pricing
            </Link>
          </li>
        </ul>
        <ul className="flex gap-8 items-center">
          {navItems2.map((n) => (
            <li key={n}>
              <a href={`#${n}`} className="text-sm font-normal text-gray-gray3">
                {n}
              </a>
            </li>
          ))}
          <li>
            <button
              className="px-4 py-1 border rounded-[30px] text-white text-sm"
              onClick={handleClick}
            >
              Sign in
            </button>
          </li>
          <li>
            <Link
              to={"/purchase"}
              className="px-4 py-2 rounded-[30px] text-sm bg-yellow-yellow1"
            >
              Buy Now
            </Link>
          </li>
        </ul>
      </nav>
      {/* mobile navbar */}
      <nav className="w-full block lg:hidden">
        <ul className="w-full flex justify-between items-center">
          <li>
            <Link className="" to="/">
              <img src={IntouchLogo} alt="" />
            </Link>
          </li>
          <li>
            <button className="text-white">
              <HiOutlineMenu
                onClick={() => setIsOpen(!isOpen)}
                className="text-white text-xl"
              />
            </button>
          </li>
        </ul>
      </nav>
      <div
        className={`bg-slate-700 w-40 rounded p-5 lg:bg-transparent absolute top-0 right-0 lg:relative z-50 lg:z-auto lg:hidden ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <button className="text-white lg:hidden">
          <HiOutlineMenu
            onClick={() => setIsOpen(!isOpen)}
            className="text-white text-xl"
          />
        </button>
        <div className="flex flex-col text-sm font-normal text-gray-gray3 text-center">
          {navItems1.map((navItem) => (
            <div onClick={() => setIsOpen(!isOpen)} className="mt-5">
              <CustomLink
                key={navItem.label}
                isHomeRoute={isHomeRoute}
                {...navItem}
              />
            </div>
          ))}
          <Link
            to="/Pricing"
            onClick={() => setIsOpen(!isOpen)}
            className="text-sm font-normal mt-5"
          >
            Pricing
          </Link>
        </div>
        <hr className="mt-5" />
        <ul className="items-center">
          {navItems2.map((n) => (
            <li key={n} className="mt-5 text-center">
              <a href={`#${n}`} className="text-sm font-normal text-gray-gray3">
                {n}
              </a>
            </li>
          ))}
          <li className="text-center">
            <button
              className="px-4 mt-5 py-1 border rounded-[30px] text-white text-sm"
              onClick={handleClick}
            >
              Sign in
            </button>
          </li>
          <li className="mt-5 text-center">
            <Link
              to={"/purchase"}
              className="px-4 py-2 mt-5 rounded-[30px] text-sm bg-yellow-yellow1"
            >
              Buy Now
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
