import { routePaths } from "../../../utils/constants";
import arrowIndicatorIcon from "../../../assets/icons/arrow-indicator.svg";
import SilverCardFront from "../../../assets/icons/SilverCardFront.svg";
import iphoneIcon from "../../../assets/images/iphone.svg";
import { useEffect, useMemo, useRef, useState } from "react";

const Hero2 = () => {
  const timerRef = useRef<any>();
  const steps = useMemo(
    () => [
      {
        step: 1,
        title: "Get your Our Intouch now",
        desc: "Take advantage of the opportunity to buy your Intouch business card, tailored specifically to your profile and interest.",
      },
      {
        step: 2,
        title: "Personalize your details",
        desc: "Visit www.BackDrop.OurIntouch.com to set up and customize your digital business card with all the details.",
      },
      {
        step: 3,
        title: "Give your business card to us.",
        desc: "Immerse yourself in unforgettable Intouch card experiences.",
      },
    ],
    []
  );
  const [step, setStep] = useState(0);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setStep((prev) => (prev === steps.length - 1 ? 0 : prev + 1));
    }, 5000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const activeSetp = useMemo(() => steps[step], [step]);
  return (
    <div
      className="flex flex-col-reverse h-screen lg:gap-0 gap-8 md:h-[125vh] lg:flex-row justify-center items-center lg:h-screen"
      id={routePaths.howItWorks}
    >
      <section className="flex-1 h-full lg:h-full gap-4 flex flex-col justify-center items-center w-full">
        <div className=" justify-start items-start flex flex-col gap-4 w-[80%]">
          <div className="flex-col justify-start items-start gap-2.5 inline-flex">
            <h3 className="text-white text-2xl lg:text-[40px] font-semibold font-Montserrat">
              How it works?
            </h3>
            <p className=" text-zinc-500 text-base font-normal font-Roboto">
              It’s as simple as 1, 2, 3 to construct your own digital business
              card.
            </p>
          </div>
          <div className="mt-8 flex relative">
            <div className="absolute -top-7 w-16 h-12">
              <img src={arrowIndicatorIcon} alt="" className="w-full h-full" />
            </div>
            <div className="relative left-16 flex flex-col gap-4">
              <span className="bg-yellow-yellow1 p-1 px-2 text-center w-fit">
                Step {activeSetp.step}
              </span>
              <h1 className="text-white text-lg lg:text-2xl   font-semibold font-Kumbh">
                {activeSetp.title}
              </h1>
              <p className=" text-neutral-500 text-sm w-[80%] font-normal font-Roboto">
                {activeSetp.desc}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="lg:h-full flex-1 w-full relative m-10 lg:m-0">
        <img
          src={SilverCardFront}
          alt="Business Card"
          className="absolute z-0 rotate-[15deg] lg:top-2 md:h-[8rem] top-2 left-[35%] md:left-[30%] lg:left-1/4 lg:w-auto lg:h-auto md:w-[24rem] h-[30%]"
        />
        <img
          src={iphoneIcon}
          alt="Business Card"
          className="absolute md:w-[80%] w-[80%] lg:h-full left-[10%] md:left-[16%] md:top-0 lg:left-0 my-auto z-10 self-start lg:bottom-0 object-cover lg:w-full"
        />
      </section>
    </div>
  );
};

export default Hero2;
