import React from "react";
import CustomTitle from "./CustomTitle";
import ChooseCard from "./ChooseCard";
import { ICard, IPlans } from "../../types/Purchase";

interface ICardSelectionProps {
  handleCardSelect: (card: ICard) => void;
  selectedCard: ICard | null;
  setPersonalCard: React.Dispatch<React.SetStateAction<ICard>>;
  setEnterpriseCard: React.Dispatch<React.SetStateAction<ICard>>;
  personalCard: ICard;
  personalPlans: IPlans[];
  enterpriseCard: ICard;
  enterprisePlans: IPlans[];
}

const Cardselection = (props: ICardSelectionProps) => {
  const {
    personalCard,
    personalPlans,
    enterpriseCard,
    enterprisePlans,
    handleCardSelect,
    setPersonalCard,
    setEnterpriseCard,
  } = props;
  return (
    <div className="w-full">
      <CustomTitle title="Choose your card" className="mb-2" />
      <ChooseCard
        card={personalCard}
        handleCardSelect={handleCardSelect}
        plans={personalPlans}
        setCard={setPersonalCard}
      />
      <ChooseCard
        card={enterpriseCard}
        handleCardSelect={handleCardSelect}
        plans={enterprisePlans}
        setCard={setEnterpriseCard}
        comingSoon={true}
      />
    </div>
  );
};

export default Cardselection;
