import React from 'react'

interface IProps {
    title: string,
    className?: string
}
const CustomTitle = (props: IProps) => {
    const { title, className } = props
    return (
        <p className={`font-Kumbh font-bold text-[16px] ${className}`}>{title}</p>
    )
}

export default CustomTitle
