import axios, { AxiosResponse } from "axios";
import { PURCHASE_DETAILS } from "./endpoints";
import { ICard, IPlans } from "../types/Purchase";

type fetchPlanProps = {
  setPersonalPlans: React.Dispatch<React.SetStateAction<IPlans[]>>;
  setEnterprisePlans: React.Dispatch<React.SetStateAction<IPlans[]>>;
  setPersonalCard: React.Dispatch<React.SetStateAction<ICard>>;
  setEnterpriseCard: React.Dispatch<React.SetStateAction<ICard>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

type IResponseData = {
  id: string;
  country: string;
  plans: IPlans[];
};
export const fetchPurchaseDetails = async ({
  setPersonalPlans,
  setEnterprisePlans,
  setLoading,
  setPersonalCard,
  setEnterpriseCard,
}: fetchPlanProps) => {
  setLoading(true);
  try {
    const response: AxiosResponse<IResponseData[]> = await axios.get(
      PURCHASE_DETAILS
    );
    const responseData = response.data.length
      ? response.data[0]
      : { plans: [] };

    const personalPlan = responseData.plans
      ?.filter((plan) => plan?.title === "Individual")
      .map((p) => ({
        annualPrice: +p.annualPrice,
        oneTimePrice: +p.oneTimePrice,
        plan: p.plan,
        title: p.title,
      }));
    const enterprisePlan = responseData.plans
      ?.filter((plan) => plan?.title === "Enterprise")
      .map((p) => ({
        annualPrice: +p.annualPrice,
        oneTimePrice: +p.oneTimePrice,
        plan: p.plan,
        title: p.title,
      }));
    setPersonalPlans(personalPlan);
    setEnterprisePlans(enterprisePlan);
    if (personalPlan.length) {
      const firstPlan = personalPlan[0];
      setPersonalCard((prev) => ({
        ...prev,
        price: firstPlan.oneTimePrice,
        plan: firstPlan.plan,
        title: firstPlan.title,
      }));
    }
    if (enterprisePlan.length) {
      const firstPlan = enterprisePlan[0];
      setEnterpriseCard((prev) => ({
        ...prev,
        price: firstPlan.oneTimePrice,
        plan: firstPlan.plan,
        title: firstPlan.title,
      }));
    }
  } catch (error) {
    console.error("PurchaseDetailsError", error);
  } finally {
    setLoading(false);
  }
};
