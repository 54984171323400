import ChatSVG from '../../assets/icons/chat-icon.svg';


const ChatIcon = () => {
    return (
        <button className='fixed bottom-4 right-4 w-12 h-12 flex justify-center items-center'>
            <img src={ChatSVG} alt='' className='w-full h-full object-cover cursor-pointer' />
        </button>
    )
}

export default ChatIcon
