import { useMemo } from "react";
import CustomTitle from "./CustomTitle";
import { ICard, ISelectedPricePlan } from "../../types/Purchase";
import CardTileIcon from "./CardTileIcon";

interface IPaymentDetailsProps {
  card: ICard;
  plans: ISelectedPricePlan[];
  setPlans: React.Dispatch<React.SetStateAction<ISelectedPricePlan[]>>;
}

const PaymentDetails = (props: IPaymentDetailsProps) => {
  const { card, plans, setPlans } = props;

  const handleDecrease = (index: number) => {
    const tempSelectedPlans = [...plans];
    const targetedPlan = tempSelectedPlans[index];
    targetedPlan.quantity -= 1;
    setPlans(tempSelectedPlans);
  };
  const handleIncrease = (index: number) => {
    const tempSelectedPlans = [...plans];
    const targetedPlan = tempSelectedPlans[index];
    targetedPlan.quantity += 1;
    setPlans(tempSelectedPlans);
  };
  const handleRemovePlan = (index: number) => {
    const tempSelectedPlans = [...plans];
    tempSelectedPlans.splice(index, 1);
    setPlans(tempSelectedPlans);
  };

  const overAllPrice = useMemo(
    () =>
      plans
        .map((p) => p.quantity * p.price)
        ?.reduce((acc, curr) => acc + curr, 0),
    [plans]
  );

  return (
    <div>
      <div className="flex items-center gap-1 w-full my-1">
        <CardTileIcon id={card?.id} />
        <CustomTitle title={card?.name} />
      </div>

      {plans?.map((plan: ISelectedPricePlan, index: number) => (
        <div key={index}>
          <div className="flex gap-2">
            <span className="text-gray-gray2 font-Roboto font-light text-[11px] flex items-center">
              Plan: <p className="ml-1 font-semibold">{plan?.plan}</p>
            </span>
            <span className="text-gray-gray2 font-Roboto font-light text-[11px] flex items-center">
              Material: <p className="ml-1 font-semibold">{plan?.material}</p>
            </span>
            <span className="text-gray-gray2 font-Roboto font-light text-[11px] flex items-center">
              Color: <p className="ml-1 font-semibold">{plan?.color}</p>
            </span>
          </div>

          <div className="flex flex-wrap justify-between items-center my-2 border-b pb-1">
            <div className="flex items-center gap-6">
              <button
                type="button"
                className="border border-gray-400 text-gray-gray2 w-4 h-4 text-xs flex items-center justify-center"
                disabled={plan.quantity === 1}
                onClick={() => handleDecrease(index)}
              >
                -
              </button>
              <p className={`font-Kumbh font-bold text-sm`}>{plan?.quantity}</p>
              <button
                type="button"
                className="border border-gray-400 text-gray-gray2 w-4 h-4 text-xs flex items-center justify-center"
                onClick={() => handleIncrease(index)}
              >
                +
              </button>
            </div>
            <div className="flex gap-4 items-center">
              <p className={`font-Kumbh font-bold text-sm`}>
                {card?.currency} {(plan?.price * plan?.quantity)?.toFixed(2)}
              </p>
              <button
                type="button"
                className="border border-gray-400 text-gray-gray2 w-4 h-4 text-xs flex items-center justify-center"
                onClick={() => handleRemovePlan(index)}
              >
                x
              </button>
            </div>
          </div>
        </div>
      ))}

      <ul className="py-2 list-none space-y-2">
        <li className="flex items-center gap-4">
          <span className="w-1.5 h-1.5 rounded-full bg-gray-gray2" />
          <p className="text-xs font-normal text-gray-gray2">
            Custom design will be created after payment
          </p>
        </li>
        <li className="flex items-center gap-4">
          <span className="w-1.5 h-1.5 rounded-full bg-gray-gray2" />
          <p className="text-xs font-normal text-gray-gray2">
            Shipping will be calculated at checkout
          </p>
        </li>
      </ul>
      <hr className="my-1" />
      <div className="mt-3">
        <CustomTitle
          title={`Order total: ${card?.currency} ${overAllPrice?.toFixed(2)} `}
        />
        <p className="text-xs font-normal text-gray-gray2 mt-1">
          ( {card?.currency} {overAllPrice?.toFixed(2)} one-time,{" "}
          {card.annualPrice
            ? `$${card.quantity * card.annualPrice}.00`
            : "$0.00"}{" "}
          per year )
        </p>
      </div>
    </div>
  );
};

export default PaymentDetails;
