import { routePaths } from "../../../utils/constants";
import Laptop from "../../../assets/images/laptop.svg";
import "../../../App.css";

const IntouchTeams = () => {
  return (
    <section
      id={routePaths.teams}
      className="h-[70vh] lg:h-screen 2xl:h-[88vh] w-full  p-5 lg:p-0  flex flex-col-reverse md:flex-row items-center justify-center"
    >
      <div className="flex flex-1 justify-center md:justify-end items-center h-full w-full z-20">
        <div className="space-y-4  md:w-[90%] w-3/4 flex flex-col justify-center md:items-start items-center">
          <h2 className="text-black text-[40px] font-semibold font-Kumbh">
            InTouch for Teams
          </h2>
          <div className="w-full lg:w-3/4">
            <p className=" text-gray-gray2 text-base font-normal font-Roboto text-left">
              Your model one-stop shop to create business card from your
              enterprise, manage your business contacts, and maximize your
              revenue.
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <button className="text-center text-black text-sm font-bold font-Inter px-5 py-2 bg-yellow-yellow1 rounded-[49px]">
              Buy Now
            </button>
            <button className="text-center text-black text-sm font-bold font-Inter px-5 py-2 border rounded-[49px]">
              View Pricing
            </button>
          </div>
        </div>
      </div>
      <div className="relative flex-1 h-full w-full mb-4 md:mb-0">
        <img
          src={Laptop}
          alt=""
          className="absolute w-full h-full md:scale-[2] lg:right-[35%] md:right-1/4"
        />
      </div>
    </section>
  );
};

export default IntouchTeams;
