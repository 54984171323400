import mobileImage from "../../../assets/images/paul-stephen-mobile.png";
import mobileImage1 from "../../../assets/images/paul-stephen-mobile3.png";
import mobileImage2 from "../../../assets/images/paul-stephen-mobile2.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useState } from "react";

function GetTemplates() {
  const [activeSlide, setActiveSlide] = useState(1);
  const silderImages = [mobileImage1, mobileImage, mobileImage2];

  const settings = {
    className: "center",
    dots: true,
    centerMode: true,
    infinite: true,
    centerPadding: "230px",
    beforeChange: (oldIndex: any, newIndex: any) => {
      setActiveSlide(newIndex);
    },
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          centerPadding: "150px",
        },
      },
    ],
  };

  return (
    <section className="w-full h-auto bg-white flex-col justify-start items-center gap-16 inline-flex pt-4 px-4 pb-16">
      <div className=" justify-center items-center flex pt-4">
        <h3 className="text-center text-black text-[32px] font-extrabold font-Kumbh uppercase tracking-[3px]">
          get your templates
        </h3>
      </div>
      <div className="w-full flex justify-center">
        <div className="md:w-[43rem] w-[29rem] h-[65vh]">
          <Slider {...settings}>
            {silderImages.map((_, i) => (
              <div>
                <img
                  key={i}
                  className={`lg:h-[65vh] md:h-[65vh] h-[50vh] w-full duration-500 transition-all ${
                    activeSlide === i ? "scale-100" : "scale-[0.8] blur-sm"
                  }`}
                  src={_}
                  alt=""
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default GetTemplates;
