import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PricePlan from "./pages/PricePlan";
import Home from "./pages/Home";
import HomeLayout from "./layout/HomeLayout";
import Purchase from "./pages/Purchase";

function App() {
  return (
    <section className="max-w-screen-2xl mx-auto">
      <Router>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index path="/" element={<Home />} />
            <Route path="/pricing" element={<PricePlan />} />
          </Route>
          <Route path="/purchase" element={<Purchase />} />
        </Routes>
      </Router>
    </section>
  );
}

export default App;
