import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import IntouchLogo from "../assets/icons/intouch-logo.svg";
import Cardselection from "../components/Purchase/Cardselection";
import Payment from "../components/Purchase/Payment";
import Review from "../components/Purchase/Review";
import PaymentDetails from "../components/Purchase/PaymentDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ICard,
  ICurrency,
  IFormValues,
  IPaymentDetails,
  IPlans,
  ISelectedPricePlan,
} from "../types/Purchase";
import PaymentDetailsStep2 from "../components/Purchase/PaymentDetailsStep2";
import PaymentSuccessModal from "../components/Purchase/PaymentSuccessModal";
import { findCurrency } from "../utils/helperfunctions";
import { fetchPurchaseDetails } from "../services/Purchase";
import { useFormik } from "formik";
import * as Yup from "yup";
import { smartCardSubmitData } from "../services/smartCardSubmit";
import { chargeCreditCard } from "../services/chargeCreditCard";
const initialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  phone: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  country: "United States (US)",
};

const initialSubmittedData = {
  Personal: {
    FirstName: null,
    MiddleName: null,
    LastName: null,
    Prefix: null,
    Suffix: null,
    PreferredName: null,
    Address: {
      Address1: null,
      Address2: null,
      City: null,
      State: null,
      Country: null,
      Zip: null,
    },
  },
  General: {
    Emails: [
      {
        Value: null,
        Name: null,
      },
    ],
    Phones: [
      {
        Value: null,
        Name: null,
      },
    ],
    Urls: [
      {
        Value: null,
        Name: null,
      },
    ],
    Links: [
      {
        Value: null,
        Name: null,
      },
    ],
    Bio: null,
  },
  Social: {
    Twitter: null,
    Instagram: {
      Value: null,
      Name: null,
    },
    LinkedIn: {
      Value: null,
      Name: null,
    },
    Facebook: { Value: null, Name: null },
    YouTube: { Value: null, Name: null },
    SnapChat: { Value: null, Name: null },
    TikTok: { Value: null, Name: null },
    Twitch: { Value: null, Name: null },
    Yelp: { Value: null, Name: null },
  },
  Business: {
    About: null,
    JobTitle: null,
    BusinessName: null,
    Department: null,
    Address: {
      Address1: null,
      Address2: null,
      City: null,
      State: null,
      Country: null,
      Zip: null,
    },
    Role: null,
  },
  Payment: {
    PayPal: {
      Value: null,
      Name: null,
    },
    Venmo: {
      Value: null,
      Name: null,
    },
    CashApp: {
      Value: null,
      Name: null,
    },
  },
  Messaging: {
    WhatsApp: {
      Value: null,
      Name: null,
    },
    Signal: {
      Value: null,
      Name: null,
    },
    Discord: {
      Value: null,
      Name: null,
    },
    Skype: {
      Value: null,
      Name: null,
    },
    Telegram: {
      Value: null,
      Name: null,
    },
  },
  cards: {
    crediCard: {
      providerName: null,
      nameOnCard: null,
      cardNo: null,
      expDate: null,
      cvv: null,
    },
  },
  SmartCardSitting: {
    DefaultTemplateName: null,
    IsProfileVerified: null,
    SubscriptionType: null,
    UserId: null,
    TemplateList: null,
  },
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid Email").required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  address1: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zip: Yup.string().required("Zip code is required"),
  country: Yup.string().required("Country is required"),
});

const Purchase = () => {
  const [currency] = useState<ICurrency>("usa");
  const [loading, setLoading] = useState(false);
  const [selectedPlans, setSelectedPlans] = useState<ISelectedPricePlan[]>([]);
  const [selectedCard, setSelectedCard] = useState<ICard | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [step, setStep] = useState(1);
  const [appliedDiscuntList, setAppliedDiscountList] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>(null);
  const [personalPlans, setPersonalPlans] = useState<IPlans[]>([]);
  const [enterprisePlans, setEnterprisePlans] = useState<IPlans[]>([]);
  const [paymentType, setPaymentType] = useState<string>("card");
  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetails>({
    cardNumber: "",
    cardName: "",
    expiryMonth: "",
    expiryYear: "",
    securityCode: "",
  });
  const cardDetail = useLocation().state;
  const [validEmailPhone, setValidEmailPhone] = useState(false);
  const [personalCard, setPersonalCard] = useState<ICard>({
    id: 1,
    name: "Individual",
    desc: "Lorem ipsum dolor sit amet. Quo nisi libero aut modi sunt id consequuntur optio. Aut asperiores quas est temporibus",
    plan: "Standard",
    title: "Individual",
    material: "Plastic",
    color: "Black",
    price: 0,
    currency: findCurrency(currency),
    quantity: 1,
  });
  const [enterpriseCard, setEnterpriseCard] = useState<ICard>({
    id: 2,
    name: "Team & Enterprise Card",
    desc: "Lorem ipsum dolor sit amet. Quo nisi libero aut modi sunt id consequuntur optio. Aut asperiores quas est temporibus",
    plan: "Enterprise",
    title: "Team",
    material: "Plastic",
    color: "Black",
    price: 0,
    currency: findCurrency(currency),
    quantity: 1,
  });
  const [submitData, setSubmitData] = useState<any>(initialSubmittedData);

  const overAllPrice = useMemo(
    () =>
      selectedPlans
        .map((p) => p.quantity * p.price)
        ?.reduce((acc, curr) => acc + curr, 0),
    [selectedPlans]
  );

  const errorToast = (message: string) => {
    toast.error(message, {
      position: "top-center",
    });
  };

  // formRef
  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (step === 2) {
        if (!validEmailPhone) {
          errorToast("Validate your Email or Phone!");
        } else if (
          (paymentType === "" || paymentType === "card") &&
          paymentDetails &&
          Object.values(paymentDetails).filter((val) => val === "").length > 0
        ) {
          errorToast("Please enter valid payment details!");
        } else {
          setStep((prev) => prev + 1);
        }

        setSubmitData((prev: any) => ({
          ...prev,
          Personal: {
            FirstName: values.firstName,
            MiddleName: null,
            LastName: values.lastName,
            Prefix: null,
            Suffix: null,
            PreferredName: null,
            Address: {
              Address1: values.address1,
              Address2: values.address2,
              City: values.city,
              State: values.state,
              Country: values.country,
              Zip: values.zip,
            },
          },
          SmartCardSitting: {
            DefaultTemplateName: "Basic01",
            IsProfileVerified: false,
            SubscriptionType: "Gold",
            UserId: values.email,
            TemplateList: ["Basic01", "Basic02", "Basic03"],
          },
          General: {
            Emails: [{ Value: values.email, Name: "email" }],
            Phones: [{ Value: values.phone, Name: "phone" }],
          },
        }));
      } else {
        setStep((prev) => prev + 1);
      }
    },
  });
  const { handleSubmit, values } = formik;

  function chooseMaterial(params: string) {
    switch (params) {
      case "Standard":
        return "Plastic";
        break;
      case "Gold":
        return "Wooden";
        break;
      case "Platinum":
        return "Metal";
        break;
      default:
        return "";
        break;
    }
  }

  useEffect(() => {
    if (cardDetail) {
      const newPlan: ISelectedPricePlan = {
        color: "Black",
        material: chooseMaterial(cardDetail.plan),
        plan: cardDetail.plan,
        price: cardDetail.oneTimePrice,
        quantity: 1,
        oneTimePrice: cardDetail.oneTimePrice,
        annualPrice: cardDetail.annualPrice,
      };
      setSelectedPlans([newPlan]);
      setSelectedCard({
        ...personalCard,
        price: cardDetail.oneTimePrice,
        oneTimePrice: cardDetail.oneTimePrice,
        annualPrice: cardDetail.annualPrice,
        plan: cardDetail.plan,
        title: cardDetail.title,
      });
      setStep(1);
    }
  }, []);

  const handleCardSelect = (card: ICard) => {
    const newPlan: ISelectedPricePlan = {
      color: card.color,
      material: card.material,
      plan: card.plan,
      price: card.price,
      quantity: card.quantity,
    };
    // if nothing selected or select a new card
    if (!selectedCard || (selectedCard && selectedCard.id !== card.id)) {
      setSelectedPlans([newPlan]);
    }
    if (selectedCard && selectedCard.id === card.id) {
      // if combo aldready there then increase the quantity
      const isSameComboPlan = selectedPlans.filter(
        (plan) =>
          plan.plan === newPlan.plan &&
          plan.material === newPlan.material &&
          plan.color === newPlan.color
      );
      if (isSameComboPlan.length) {
        const tempSelectedPlans = [...selectedPlans];
        const sameComboPlan = isSameComboPlan[0];
        sameComboPlan.quantity = sameComboPlan.quantity + 1;
        setSelectedPlans(tempSelectedPlans);
      } else {
        setSelectedPlans((prev) => [...prev, newPlan]);
      }
      // if combo not there then create new card with quantity 1
    }
    setSelectedCard(card);
  };

  useEffect(() => {
    fetchPurchaseDetails({
      setEnterprisePlans,
      setPersonalPlans,
      setLoading,
      setPersonalCard,
      setEnterpriseCard,
    });
  }, []);

  const isCardSelected = useMemo(
    () => selectedCard && selectedPlans.length,
    [selectedCard, selectedPlans]
  );

  const submitSmartCardData = () => {
    if (step === 3) {
      smartCardSubmitData({ setLoading, payload: submitData }).then(() => {
        chargeCreditCard({
          setLoading,
          payload: { amount: overAllPrice },
        }).then(() => {
          setShowSuccessModal(true);
        });
      });
    }
  };

  return (
    <main className="w-full h-auto">
      <header className="w-full h-auto p-4 md:p-0 md:h-12 flex justify-center bg-black-black1 sticky top-0 z-50">
        <nav className="px-4 md:px-0 flex flex-col md:flex-row w-full h-full md:w-[80vw] item-center gap-2">
          <div className="flex-[1] flex items-center">
            <Link className="mb-2 md:mb-0" to="/">
              <img src={IntouchLogo} alt="" />
            </Link>
          </div>
          <ul className=" flex-[11] list-none flex justify-center gap-8 flex-wrap">
            <li className="flex items-center gap-2">
              <div
                className={`w-6 h-6 rounded-full flex justify-center items-center text-sm ${
                  step >= 1
                    ? "bg-yellow-yellow1"
                    : "border-[2px] border-white text-white"
                }`}
              >
                1
              </div>
              <p className="text-white text-sm font-bold font-Roboto">
                Select Your card
              </p>
            </li>
            <li className="flex items-center gap-2">
              <div
                className={`w-6 h-6 rounded-full flex justify-center items-center text-sm ${
                  step >= 2
                    ? "bg-yellow-yellow1"
                    : "border-[2px] border-white text-white"
                }`}
              >
                2
              </div>
              <p className="text-white text-sm font-bold font-Roboto">
                Payment
              </p>
            </li>
            <li className="flex items-center gap-2">
              <div
                className={`w-6 h-6 rounded-full flex justify-center items-center text-sm ${
                  step >= 3
                    ? "bg-yellow-yellow1"
                    : "border-[2px] border-white text-white"
                }`}
              >
                3
              </div>
              <p className="text-white text-sm font-bold font-Roboto">Review</p>
            </li>
            <li className="flex items-center gap-2">
              <div
                className={`w-6 h-6 rounded-full flex justify-center items-center text-sm ${
                  step >= 4
                    ? "bg-yellow-yellow1"
                    : "border-[2px] border-white text-white"
                }`}
              >
                4
              </div>
              <p className="text-white text-sm font-bold font-Roboto">
                Complete
              </p>
            </li>
          </ul>
        </nav>
      </header>
      <form onSubmit={handleSubmit}>
        <section className="w-full flex justify-center items-stretch h-auto md:min-h-[calc(100vh-96px)] bg-gray-gray6 p-4">
          {loading ? (
            <p>loading...</p>
          ) : (
            <div className="w-full md:w-[82%] flex flex-col md:flex-row items-stretch justify-between h-full gap-4">
              <article className="flex-[8] bg-white p-4 rounded-sm drop-shadow-xl">
                {step === 1 ? (
                  <Cardselection
                    personalCard={personalCard}
                    personalPlans={personalPlans}
                    enterprisePlans={enterprisePlans}
                    enterpriseCard={enterpriseCard}
                    handleCardSelect={handleCardSelect}
                    selectedCard={selectedCard}
                    setPersonalCard={setPersonalCard}
                    setEnterpriseCard={setEnterpriseCard}
                  />
                ) : null}
                {step === 2 ? (
                  <Payment
                    {...formik}
                    setValidEmailPhone={setValidEmailPhone}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    paymentDetails={paymentDetails}
                    setPaymentDetails={setPaymentDetails}
                  />
                ) : null}
                {step === 3 ? (
                  <Review values={values} paymentDetails={paymentDetails} />
                ) : null}
              </article>
              <article className="flex-[4] bg-white p-4 rounded-sm drop-shadow-xl">
                {selectedCard && selectedPlans.length && step === 1 ? (
                  <PaymentDetails
                    card={selectedCard}
                    plans={selectedPlans}
                    setPlans={setSelectedPlans}
                  />
                ) : (
                  <>{step === 1 ? <p>Please Choose Your card.</p> : null}</>
                )}
                {selectedCard &&
                selectedPlans.length &&
                (step === 2 || step === 3) ? (
                  <PaymentDetailsStep2
                    card={selectedCard}
                    plans={selectedPlans}
                    currentStep={step}
                    appliedDiscuntList={appliedDiscuntList}
                    setAppliedDiscountList={setAppliedDiscountList}
                    totalAmount={totalAmount}
                    setTotalAmount={setTotalAmount}
                  />
                ) : null}
              </article>
            </div>
          )}
        </section>
        <footer className="w-full  h-auto p-4 md:p-0 md:h-12 flex justify-center bg-black-black1 ">
          {step === 1 ? (
            <nav className="px-4 md:px-0 flex w-full h-full md:w-[80vw] items-center justify-end">
              <div className="flex items-center gap-2">
                <Link
                  to="/"
                  className="px-4 py-1 border rounded-[30px] text-white text-sm"
                >
                  Cancel
                </Link>
                <button
                  type="button"
                  className="px-4 py-1 rounded-[30px] text-sm bg-yellow-yellow1"
                  onClick={() => setStep((prev) => prev + 1)}
                  disabled={!isCardSelected}
                >
                  Continue
                </button>
              </div>
            </nav>
          ) : null}
          {step === 2 ? (
            <nav className="px-4 md:px-0 flex w-full h-full md:w-[80vw] items-center justify-between">
              <button
                type="button"
                className="px-4 py-1 border rounded-[30px] text-white text-sm"
                onClick={() => setStep((prev) => prev - 1)}
              >
                Back
              </button>
              <div className="flex items-center gap-2">
                <Link
                  to="/"
                  className="px-4 py-1 border rounded-[30px] text-white text-sm"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="px-4 py-1 rounded-[30px] text-sm bg-yellow-yellow1"
                >
                  Continue
                </button>
              </div>
            </nav>
          ) : null}
          {step === 3 ? (
            <nav className="px-4 md:px-0 flex w-full h-full md:w-[80vw] items-center justify-between">
              <button
                type="button"
                className="px-4 py-1 border rounded-[30px] text-white text-sm"
                onClick={() => setStep((prev) => prev - 1)}
              >
                Back
              </button>
              <div className="flex items-center gap-2">
                <Link
                  to="/"
                  className="px-4 py-1 border rounded-[30px] text-white text-sm"
                >
                  Cancel
                </Link>
                <button
                  type="button"
                  className="px-4 py-1 rounded-[30px] text-sm bg-yellow-yellow1"
                  onClick={submitSmartCardData}
                >
                  Pay Now
                </button>
              </div>
            </nav>
          ) : null}
        </footer>
      </form>
      {showSuccessModal ? (
        <PaymentSuccessModal setShowSuccessModal={setShowSuccessModal} />
      ) : null}
      <ToastContainer />
    </main>
  );
};

export default Purchase;
