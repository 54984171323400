export const routePaths = {
    howItWorks: 'howItWorks',
    features: 'features',
    teams: 'teams',
    card: 'card',
}

export const purchasePlans = {
    Standard: "Standard",
    Gold: "Gold",
    Platinum: "Platinum",
    Enterprise: "Enterprise"
}

export const purchaseMaterials = {
    Plastic: "Plastic",
    Wooden: "Wooden",
    Metal: "Metal"
}