import axios from "axios";
import { USER_CHECK_EMAIL, USER_CHECK_PHONE } from "./endpoints";
import { userEmailType, userPhoneType } from "../types/verifyUser";

export const verifyUserEmail = async ({
  payload,
  setLoading,
  setIsExist,
}: {
  payload: userEmailType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExist?: any;
}) => {
  const { email } = payload;
  setLoading(true);
  try {
    const response = await axios.get(`${USER_CHECK_EMAIL}/${email}`);
    if (setIsExist && response.data == true) {
      setIsExist(true);
    }
    if (setIsExist && response.data == false) {
      setIsExist(false);
    }
  } catch (error) {
    console.error("Card Submission Failed!", error);
    if (setIsExist) {
      setIsExist(false);
    }
  } finally {
    setLoading(false);
  }
};

export const verifyUserPhone = async ({
  payload,
  setLoading,
  setIsExist,
}: {
  payload: userPhoneType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsExist?: any;
}) => {
  const { phoneNumber } = payload;
  setLoading(true);
  try {
    const response = await axios.get(`${USER_CHECK_PHONE}/${phoneNumber}`);
    if (setIsExist && response.data == true) {
      setIsExist(true);
    }
    if (setIsExist && response.data == false) {
      setIsExist(false);
    }
  } catch (error) {
    console.error("Card Submission Failed!", error);
    if (setIsExist) {
      setIsExist(false);
    }
  } finally {
    setLoading(false);
  }
};
