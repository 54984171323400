import React, { useMemo, useEffect } from "react";
import PlasticCard from "../../assets/svg/plasticCard.svg";
import SilverCard from "../../assets/svg/SilverCard.svg";
import WoodenCard from "../../assets/svg/woodenCard.svg";
import PlasticCardBack from "../../assets/images/PlasticCardBack.png";
import GoldCardBack from "../../assets/images/GoldCardBack.png";
import SilverCardBack from "../../assets/images/SilverCardBack.png";
import CustomTitle from "./CustomTitle";
import "./Purchase.css";
import { ICard, IPlans } from "../../types/Purchase";
import { purchaseMaterials, purchasePlans } from "../../utils/constants";
import CardTileIcon from "./CardTileIcon";

interface IChooseCardProps {
  card: ICard;
  handleCardSelect: (card: ICard) => void;
  setCard: React.Dispatch<React.SetStateAction<ICard>>;
  plans: IPlans[];
  comingSoon?: boolean;
}

const ChooseCard = (props: IChooseCardProps) => {
  const { card, handleCardSelect, setCard, plans, comingSoon = false } = props;
  const defaultMaterials = [
    purchaseMaterials.Plastic,
    purchaseMaterials.Wooden,
    purchaseMaterials.Metal,
  ];
  const materialColors: any = useMemo(
    () => ({
      [purchaseMaterials.Metal]:
        "linear-gradient(132.76deg, #ACACAC 0.96%, #FCFCFC 52.52%, #B6B6B6 100.96%)",
      [purchaseMaterials.Plastic]:
        "linear-gradient(132.76deg, #F6F6F6 0.96%, #EEEEEE 100.96%)",
      [purchaseMaterials.Wooden]:
        "linear-gradient(132.76deg, #FFD28E 0.96%, #AB876D 100.96%)",
    }),
    []
  );
  const materials = useMemo(() => {
    let _materials = [purchaseMaterials.Plastic];
    if (card.plan === purchasePlans.Gold)
      _materials = [purchaseMaterials.Plastic, purchaseMaterials.Wooden];
    if (card.plan === purchasePlans.Platinum)
      _materials = [
        purchaseMaterials.Plastic,
        purchaseMaterials.Wooden,
        purchaseMaterials.Metal,
      ];
    return _materials;
  }, [card.plan]);

  const color = useMemo(() => {
    let _color = "#000";
    if (card.material === purchaseMaterials.Wooden)
      _color = "linear-gradient(132.76deg, #FFD28E 0.96%, #AB876D 100.96%)";
    if (card.material === purchaseMaterials.Metal)
      _color =
        "linear-gradient(132.76deg, #ACACAC 0.96%, #FCFCFC 52.52%, #B6B6B6 100.96%)";
    return _color;
  }, [card.material]);
  const imageSrc = useMemo(() => {
    let src = { front: PlasticCard, back: PlasticCardBack };
    if (card.material === purchaseMaterials.Wooden)
      src = { front: WoodenCard, back: GoldCardBack };
    if (card.material === purchaseMaterials.Metal)
      src = { front: SilverCard, back: SilverCardBack };
    return src;
  }, [card.material]);

  const handlePlanSelection = (plan: IPlans) => {
    setCard((prev: any) => ({ ...prev, plan: plan.plan }));
  };
  const handleMaterialSelection = (material: string) => {
    let color = "Black";
    if (material === purchaseMaterials.Plastic) color = "Black";
    if (material === purchaseMaterials.Wooden) color = "Wooden";
    if (material === purchaseMaterials.Metal) color = "Silver";
    setCard((prev: any) => ({ ...prev, material, color }));
  };
  const addCardToCart = (card: ICard) => {
    let color = "Black";
    if (card.material === purchaseMaterials.Plastic) color = "Black";
    if (card.material === purchaseMaterials.Wooden) color = "Wooden";
    if (card.material === purchaseMaterials.Metal) color = "Silver";
    handleCardSelect({ ...card, color });
  };

  useEffect(() => {
    if (card.plan === purchasePlans.Standard) {
      const standardPlan = plans.find(
        (plan) => plan.plan === purchasePlans.Standard
      );
      setCard((prev: any) => ({
        ...prev,
        material: purchaseMaterials.Plastic,
        price: standardPlan?.oneTimePrice || 0,
      }));
    }
    if (card.plan === purchasePlans.Gold) {
      const goldPlan = plans.find((plan) => plan.plan === purchasePlans.Gold);
      setCard((prev: any) => ({
        ...prev,
        material: purchaseMaterials.Wooden,
        price: goldPlan?.oneTimePrice || 0,
      }));
    }
    if (card.plan === purchasePlans.Platinum) {
      const platinumPlan = plans.find(
        (plan) => plan.plan === purchasePlans.Platinum
      );
      setCard((prev: any) => ({
        ...prev,
        material: purchaseMaterials.Metal,
        price: platinumPlan?.oneTimePrice || 0,
      }));
    }
    if (card.plan === purchasePlans.Enterprise) {
      setCard((prev: any) => ({
        ...prev,
        material: purchaseMaterials.Plastic,
      }));
    }
  }, [card.plan]);

  return (
    <div className="border bg-gray-gray7 p-2 rounded-sm flex flex-col md:flex-row gap-4 my-2">
      {/* img */}
      <div className="flip-card relative w-full h-40 md:h-40 lg:h-40 flex justify-center items-center">
        <div className="flip-card-inner">
          <div className="flip-card-front h-full absolute z-[2] inset-0 mx-auto flex justify-center items-center">
            <img
              className="border flip-card-img border-white"
              src={imageSrc.front}
              alt="Front"
            />
          </div>
          <div className="flip-card-back h-full absolute inset-0 mx-auto flex justify-center items-center">
            <img
              className="border flip-card-img border-white"
              src={imageSrc.back}
              alt="Back"
            />
          </div>
        </div>
      </div>
      {/* card info details */}
      <div className="w-full">
        <div className="flex items-center gap-1 w-full my-1">
          <CardTileIcon id={card.id} />
          <CustomTitle title={card?.name} />
        </div>
        <p className="font-Roboto font-normal text-[11px] my-1 text-gray-gray8">
          Lorem ipsum dolor sit amet. Quo nisi libero aut modi sunt id
          consequuntur optio. Aut asperiores quas est temporibus
        </p>
        <hr className="my-2" />
        {card.title === "Enterprise" ? (
          <>
            <div className="my-2">
              <CustomTitle title="Plans" />
              <div className="flex items-center gap-4 my-1 flex-wrap">
                {plans.map((plan: IPlans, index: number) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handlePlanSelection(plan)}
                    className={`bg-white border rounded-[3.5rem] px-2 py-1 text-xs shadow-sm  ${
                      card.plan === plan.plan
                        ? "border-red-red1 text-black font-extrabold"
                        : "text-gray-500"
                    }`}
                  >
                    {plan.plan}
                  </button>
                ))}
              </div>
            </div>
            <hr className="my-1" />
          </>
        ) : null}
        {card.title === "Individual" ? (
          <>
            <div className="my-2">
              <CustomTitle title="Plans" />
              <div className="flex items-center gap-4 my-1 flex-wrap">
                {plans.map((plan: IPlans, index: number) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handlePlanSelection(plan)}
                    className={`bg-white border rounded-[3.5rem] px-2 py-1 text-xs shadow-sm  ${
                      card.plan === plan.plan
                        ? "border-red-red1 text-black font-extrabold"
                        : "text-gray-500"
                    }`}
                  >
                    {plan.plan}
                  </button>
                ))}
              </div>
            </div>
            <hr className="my-1" />
          </>
        ) : null}
        <div className="my-2 flex items-center gap-4 flex-wrap">
          <div>
            <CustomTitle title="Material" />
            <div className="flex items-center gap-2 my-1 flex-wrap">
              {card.title === "Individual"
                ? materials.map((material: string, index: number) => (
                    <button
                      type="button"
                      key={index}
                      onClick={() => handleMaterialSelection(material)}
                      className={`bg-white border rounded-[3.5rem] px-2 py-1 text-xs shadow-sm flex items-center gap-2 ${
                        card.material === material
                          ? "border-red-red1 text-black font-extrabold"
                          : "text-gray-500"
                      }`}
                    >
                      <div
                        className="w-4 h-4 rounded-full"
                        style={{
                          background: materialColors[material],
                        }}
                      />
                      <p>{material}</p>
                    </button>
                  ))
                : null}
              {card.title === "Enterprise"
                ? defaultMaterials.map((material: string, index: number) => (
                    <button
                      type="button"
                      key={index}
                      onClick={() => handleMaterialSelection(material)}
                      className={`bg-white border rounded-[3.5rem] px-2 py-1 text-xs shadow-sm flex items-center gap-2 ${
                        card.material === material
                          ? "border-red-red1 text-black font-extrabold"
                          : "text-gray-500"
                      }`}
                    >
                      <div
                        className="w-4 h-4 rounded-full"
                        style={{
                          background: materialColors[material],
                        }}
                      />
                      <p>{material}</p>
                    </button>
                  ))
                : null}
            </div>
          </div>
          <div>
            <CustomTitle title="Color" />
            <div className="flex items-center gap-2 my-1">
              <div
                className="h-6 w-6 rounded-full flex justify-center items-center"
                style={{ background: color }}
              >
                <div className="w-5 h-5 rounded-full bg-white flex justify-center items-center">
                  <span
                    className={`w-3 h-3 rounded-full`}
                    style={{ background: color }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-2" />
        <div className="flex items-center justify-between flex-wrap my-1">
          <CustomTitle title={`${card?.currency} ${card?.price?.toFixed(2)}`} />
          <button
            type="button"
            className="px-4 py-1 rounded-[30px] text-sm bg-yellow-yellow1"
            onClick={() => addCardToCart(card)}
            disabled={comingSoon}
          >
            {comingSoon ? "Coming soon..." : "Add to Cart"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseCard;
