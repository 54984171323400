import intouchIcon from "../../assets/icons/intuch.svg";
import instaIcon from "../../assets/icons/insta.svg";
import visaIcon from "../../assets/icons/visa.svg";
import paybalIcon from "../../assets/icons/paybal.svg";
import masterIcon from "../../assets/icons/master.svg";
import whatsappIcon from "../../assets/icons/whatsapp.svg";
import twitterIcon from "../../assets/icons/twiter.svg";
import fbIcon from "../../assets/icons/fb.svg";
import { Link } from "react-router-dom";
const fbLink = "https://www.facebook.com/profile.php?id=61557670435088";
const twitterLink = "https://twitter.com/OurIntouch";
const whtappLink = "";
const instaLink = "https://www.instagram.com/ourintouch/";
function Footer() {
  return (
    <footer className="bg-yellow-yellow1 w-full flex justify-center">
      <div className="md:w-[90%] w-full md:p-6 p-8">
        <div className="flex flex-col md:flex-row items-center md:items-start flex-wrap gap-4">
          <div className="flex-1 flex">
            <Link to={"/"}>
              <img src={intouchIcon} alt="intouch icon" />
            </Link>
          </div>
          <div className="flex flex-wrap md:hidden gap-2 w-[80%] items-center justify-center mt-6 mb-6">
            <a
              href="#howItWorks"
              className="p-2 bg-white bg-opacity-30 whitespace-nowrap text-slate-950 text-sm font-normal fonRoboto capitalize"
            >
              How it works
            </a>
            <a
              href="#features"
              className="p-2 bg-white bg-opacity-30 whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
            >
              Features
            </a>
            <a
              href="#teams"
              className="p-2 bg-white bg-opacity-30 whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
            >
              Teams
            </a>
            <a
              href="#card"
              className="p-2 bg-white bg-opacity-30 whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
            >
              Cards
            </a>
            <Link
              to={"/Pricing"}
              className="p-2 bg-white bg-opacity-30 whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
            >
              Pricing
            </Link>
            <a
              href="#Contact"
              className="p-2 bg-white bg-opacity-30 whitesaace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
            >
              Contact
            </a>
            <p className="p-2 bg-white bg-opacity-30 whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize">
              Help
            </p>
            <p className="p-2 bg-white bg-opacity-30 whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize">
              News
            </p>
          </div>
          <div className="flex-1 hidden md:block">
            <div className="flex flex-col gap-4 justify-center items-center">
              <h3 className="text-center w-full text-slate-950 text-base font-bold font-Kumbh uppercase  ">
                QUICK LINKS
              </h3>
              <div className="flex justify-center w-full">
                <div className="flex  gap-2">
                  <div className="flex flex-col gap-4">
                    <a
                      href="#howItWorks"
                      className="whitespace-nowrap text-slate-950 text-sm font-normal fonRoboto capitalize"
                    >
                      How it works
                    </a>
                    <a
                      href="#features"
                      className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
                    >
                      Features
                    </a>
                    <a
                      href="#teams"
                      className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
                    >
                      Teams
                    </a>
                    <a
                      href="#card"
                      className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
                    >
                      Cards
                    </a>
                    <Link
                      to={"/Pricing"}
                      className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
                    >
                      Pricing
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden flex-col gap-4 justify-center items-center md:flex">
            <h3 className="text-center w-full text-slate-950 text-base font-bold font-Kumbh uppercase  ">
              Resources
            </h3>
            <div className="flex flex-col gap-4">
              <a
                href="#Contact"
                className="whitesaace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
              >
                Contact{" "}
              </a>
              <p className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize">
                Help
              </p>
              <p className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize">
                News
              </p>
              <Link
                to={"/Pricing"}
                className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
              >
                Terms & Conditions
              </Link>
              <Link
                to={"/Pricing"}
                className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
              >
                Privacy Policy
              </Link>
              <Link
                to={"/Pricing"}
                className="whitespace-nowrap text-slate-950 text-sm font-normal font-Roboto capitalize"
              >
                Terms of Use
              </Link>
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-center items-center md:items-start gap-2 md:gap-4">
            <h3 className=" text-slate-950 text-lg font-black font-Kumbh uppercase">
              how are we doing?
            </h3>
            <div className="flex justify-center items-start">
              <div className="flex-col flex justify-start items-start gap-0.5 ">
                <p className="text-center text-slate-950 text-[13px] font-bold font-Karla capitalize">
                  intouch@SaaSGenie.org
                </p>
                <p className="text-center text-slate-950 text-[11px] font-normal font-Karla capitalize">
                  Customer Support
                </p>
              </div>
            </div>
          </div>

          <div className="flex-1 flex-col items-center md:items-start flex md:mt-0 mt-5 gap-2 md:gap-4">
            <h3 className="text-slate-950 text-lg font-black font-Kumbh uppercase">
              We’re Social
            </h3>
            <div className="flex items-center flex-wrap gap-6">
              <div className="w-8 h-8">
                <a href={instaLink} target="_blank">
                  <img src={instaIcon} alt="insta icon" />
                </a>
              </div>
              <div className="w-8 h-8">
                <img src={whatsappIcon} alt="whatsapp icon" />
              </div>
              <div className="w-8 h-8">
                <a href={twitterLink} target="_blank">
                  <img src={twitterIcon} alt="twiter icon" />
                </a>
              </div>
              <div className="w-8 h-8">
                <a href={fbLink} target="_blank">
                  <img src={fbIcon} alt="fb icon"></img>
                </a>
              </div>
            </div>
          </div>

          <div className="flex-1 flex-col items-center md:items-start flex md:gap-4 gap-2 md:mt-0 mt-5">
            <h3 className="text-center text-slate-950 text-lg font-black font-Kumbh uppercase">
              Payment Options
            </h3>
            <div className="flex items-center flex-wrap gap-3">
              <div className="w-8 h-8">
                <img src={visaIcon} alt="visa icon" />
              </div>
              <div className="w-8 h-8">
                <img src={paybalIcon} alt="paybal icon" />
              </div>
              <div className="w-8 h-8">
                <img src={masterIcon} alt="master icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
