function ContactUs() {
  return (
    <section
      className="w-full h-auto py-10 px-4 bg-black-black2 justify-center inline-flex"
      id="Contact"
    >
      <div className="flex flex-col justify-start items-center gap-10 w-3/4 lg:w-1/2">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-1 justify-center">
            <span className="text-yellow-yellow1 text-[32px] font-extrabold font-Kumbh uppercase tracking-[3.52px]">
              contact
            </span>
            <span className="text-white text-[32px] font-extrabold font-Kumbh uppercase tracking-[3.52px]">
              {" "}
              us
            </span>
          </div>
          <div className="text-center text-white text-sm font-light font-Roboto Flex">
            Fill out and submit your information so that we can reach out to you
            faster.
          </div>
        </div>
        <div className=" flex-col gap-5 flex w-full">
          <div className="flex items-center flex-col md:flex-row justify-between gap-4 w-full">
            <div className="flex flex-col gap-2 flex-1 w-full">
              <div className=" text-white text-xs font-light font-Roboto ">
                Full name*
              </div>
              <input className=" bg-white text-white  bg-opacity-10 rounded-sm border p-2 border-none outline-none" />
            </div>
          </div>
          <div className="flex items-center flex-col md:flex-row justify-between gap-4 w-full">
            <div className="flex flex-col gap-2 flex-1 w-full">
              <div className=" text-white text-xs font-light font-Roboto ">
                Phone
              </div>
              <input className=" bg-white text-white  bg-opacity-10 rounded-sm border p-2 border-none outline-none" />
            </div>
            <div className="flex flex-col gap-2 flex-1 w-full">
              <div className=" text-white text-xs font-light font-Roboto Flex">
                Email Address*
              </div>
              <input className=" bg-white text-white  bg-opacity-10 rounded-sm border p-2 border-none outline-none" />
            </div>
          </div>
          <div className="flex flex-col gap-2 flex-1 w-full">
            <div className=" text-white text-xs font-light font-Roboto Flex">
              How can we help you?
            </div>
            <textarea
              className=" bg-white text-white p-2 bg-opacity-10 rounded-sm resize-none border-none outline-none"
              rows={4}
            />
          </div>
          <div className="justify-center items-center flex">
            <button className="text-center text-black bg-yellow-yellow1 rounded-[49px] text-base font-bold font-Inter w-fit px-8 py-2">
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
