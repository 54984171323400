import { useEffect, Fragment } from "react";
import ContactUs from "../components/Home/contactUs/ContactUs";
import GetTemplates from "../components/Home/getTemplates/GetTemplates";
import Hero1 from "../components/Home/hero1/Hero1";
import Hero2 from "../components/Home/hero2/Hero2";
import Hero3 from "../components/Home/hero3/Hero3";
import IntouchTeams from "../components/Home/intouchTeams/IntouchTeams";
import NfcCards from "../components/Home/nfcCards/NfcCards";
import { useLocation } from "react-router-dom";
import ChatIcon from "../components/common/ChatIcon";
import "../App.css";

const Home = () => {
  const { state } = useLocation();
  useEffect(() => {
    if (state && state?.path) {
      const element = document.getElementById(state?.path);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [state]);

  return (
    <Fragment>
      <div className="bg-home-rectangle w-full h-full">
        <Hero1 />
        <Hero2 />
        <Hero3 />
      </div>
      <IntouchTeams />
      <NfcCards />
      <GetTemplates />
      <ContactUs />
      <ChatIcon />
    </Fragment>
  );
};

export default Home;
