import { useEffect, useMemo, useState } from "react";
import PriceCard from "../components/PricePlan/PriceCard";
import { fetchPlans } from "../services/PricePlan";
import { PlansType } from "../types/PricePlan";

const PricePlan = () => {
  const [plans, setPlans] = useState<PlansType>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0 });
    }
    fetchPlans({ setLoading, setPlans });
  }, []);
  const teamsPricing = useMemo(() => {
    if (Object.keys(plans).length > 0) {
      return plans?.plans?.find((p) => p?.title === "Enterprise");
    }
    return {};
  }, [Object.keys(plans).length]);

  return (
    <main className="h-auto w-full min-h-[calc(100vh-64px)]  !py-6 lg:py-0  flex  items-start  justify-center bg-gray-gray1">
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <section className="flex flex-col gap-4 w-[90%]">
          <article className="w-full flex flex-col sm:flex-row justify-between items-center gap-2">
            <h2 className="font-Kodchasan text-xl md:text-[30px] font-bold">
              Pricing
            </h2>
          </article>
          <article className="flex flex-col mb-6 items-stretch lg:flex-row  justify-between gap-4">
            <div className="flex flex-col lg:flex-row lg:flex-[9] md:flex-[12] gap-4 lg:gap-0 bg-transparent lg:bg-white rounded-md overflow-hidden items-start w-full">
              {plans?.plans
                ?.filter((p) => p?.title !== "Enterprise")
                ?.map((plan, index) => (
                  <PriceCard
                    btnLabel={"Get Started"}
                    priceDetail={plan}
                    showBorder={index === 1}
                    key={index}
                    country={plans?.country}
                  />
                ))}
            </div>
            <div className="lg:flex-[3] bg-white rounded-md overflow-hidden md:flex-[12] w-full relative">
              <PriceCard
                isYellowBtn
                btnLabel={"Coming Soon..."}
                priceDetail={teamsPricing}
                country={plans?.country}
              />
            </div>
          </article>
        </section>
      )}
    </main>
  );
};

export default PricePlan;
