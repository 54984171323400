import axios from "axios";
import { CREDIT_CARD_CHARGE } from "./endpoints";
import { CreditCardCharge } from "../types/chargeCreditCard";

type fetchPlanProps = {
  payload: CreditCardCharge;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const chargeCreditCard = async ({
  payload,
  setLoading,
}: fetchPlanProps) => {
  const updatedPayoad = {
    apiLoginID: process.env.REACT_APP_API_LOGIN_ID,
    apiTransactionKey: process.env.REACT_APP_API_TRANSACTION_KEY,
    amount: payload.amount,
  };
  setLoading(true);
  try {
    const response = await axios.post(CREDIT_CARD_CHARGE, updatedPayoad);
    console.log(response);
  } catch (error) {
    console.error("Card Submission Failed!", error);
  } finally {
    setLoading(false);
  }
};
