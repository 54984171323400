import { routePaths } from "../../../utils/constants";
import iphoneIcon from "../../../assets/images/hero-3-iphone.svg";
import SilverCardFront from "../../../assets/icons/SilverCardFront.svg";
import hero3line from "../../../assets/images/hero-3-line.svg";
import hero3line2 from "../../../assets/images/hero-3-line-2.svg";

import horizontalLine from "../../../assets/images/Vector-horizontal-line.svg";
import qrcodeIcon from "../../../assets/images/qr-code.svg";
import androidIosIcon from "../../../assets/images/android-ios.svg";
import nfcIcon from "../../../assets/images/nfcIcon.svg";
import { useEffect, useState } from "react";

const Hero3 = () => {
  const [lineStep, setLineStep] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if (lineStep === 1) {
        setLineStep(2);
      }
      if (lineStep === 2) {
        setLineStep(3);
      }
      if (lineStep === 3) {
        setLineStep(1);
      }
    }, 1000);
  }, [lineStep]);

  return (
    <div
      className="flex justify-center items-center h:auto md:flex-row lg:flex-row flex-col-reverse p-8 pb-10 md:p-0 lg:h-screen"
      id={routePaths.features}
    >
      <section className="h-full flex-1 md:flex justify-center items-center hidden">
        <img
          src={iphoneIcon}
          alt="Business Card"
          className="w-full z-10 h-full object-cover lg:ml-[15%] md:ml-[25%]"
        />
      </section>

      <section className="h-screen w-full md:h-full lg:h-full flex-1 flex lg:items-center justify-center lg:justify-start ">
        <div className="md:items-center relative  justify-center lg:justify-start lg:items-start md:gap-24 gap-52 flex flex-col">
          <div className="flex relative w-full justify-center">
            {lineStep === 1 || lineStep === 3 ? (
              <img
                src={hero3line}
                alt="Business Card"
                className="absolute md:h-auto h-56 top-[45%] md:left-10 lg:left-6 left-3"
              />
            ) : (
              <>
                <img
                  src={hero3line2}
                  alt="Business Card"
                  className="absolute hidden md:block md:h-auto h-56 lg:w-[68%] top-[45%] md:top-[10rem] md:-left-[10rem] lg:left-[-18.5rem] lg:top-[9rem] left-3"
                />
                <img
                  src={hero3line}
                  alt="Business Card"
                  className="absolute scale-x-[-1] -rotate-90 block md:hidden h-[11rem] top-[9rem] left-[5.5rem]"
                />
              </>
            )}
            <div className="relative -left-14 z-40">
              <img
                src={SilverCardFront}
                alt="Business Card"
                className="ml-5 w-48 h-20 md:h-[90%] md:w-[90%] lg:w-auto lg:h-auto"
              />
            </div>
          </div>
          <img
            src={iphoneIcon}
            alt="Business Card"
            className="w-[90%] h-[90%] p-5 absolute m-auto inset-0 md:hidden"
          />
          <div className="flex gap-4 items-center  md:justify-center lg:justify-start lg:items-start md:p-5 lg:p-0">
            <div className="flex gap-4 w-auto">
              <div className="lg:w-16 lg:h-24 h-16 flex items-center">
                <img
                  src={
                    lineStep === 1
                      ? nfcIcon
                      : lineStep === 2
                      ? androidIosIcon
                      : qrcodeIcon
                  }
                  alt="Business Card"
                  className="lg:w-[85%] lg:h-full "
                />
              </div>
              <img src={horizontalLine} />
              <div className="flex flex-col gap-2 align-baseline justify-center">
                <h3 className="text-white text-xl font-semibold font-Montserrat">
                  {lineStep === 1
                    ? "NFC Chip"
                    : lineStep === 2
                    ? "Device Support"
                    : "QR Code"}
                </h3>
                <p className="w-full lg:w-[65%] text-stone-300 text-xs  font-extralight font-Roboto">
                  Tap your card on a phone and share your contact details
                  without contact.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero3;
