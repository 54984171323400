import { BsCheckLg } from "react-icons/bs";
import Button from "./components/button/Button";
import { detectCurrency } from "../../utils/helperfunctions";
import { PlanType } from "../../types/PricePlan";
import { useNavigate } from "react-router-dom";

type PriceCardProps = {
  priceDetail: PlanType | undefined | any;
  showBorder?: boolean;
  isYellowBtn?: boolean;
  btnLabel?: string;
  country?: string;
};
const PriceCard = (props: PriceCardProps) => {
  const {
    priceDetail,
    showBorder,
    isYellowBtn,
    btnLabel,
    country = "USA",
  } = props;

  const navigate = useNavigate();
  return (
    <div
      className={`w-full lg:min-h-[50vh] h-full  p-4 py-8 bg-white ${
        showBorder ? "border-l-[1px] border-r-[1px]" : ""
      } relative`}
    >
      <div className="w-full flex flex-col justify-start mb-2">
        <span className="uppercase text-[6px] font-Montserrat">
          {priceDetail?.title}
        </span>
        <p className="font-Montserrat text-sm font-semibold">
          {priceDetail?.plan}
        </p>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <h3 className="text-2xl font-semibold font-Montserrat">
            {detectCurrency(country)}
            {priceDetail?.oneTimePrice}
          </h3>
          <span className="text-[12px] font-bold font-Montserrat">
            one time
          </span>
        </div>
        <p className="text-gray-300 font-normal text-sm font-Roboto">
          ${priceDetail?.annualPrice} per year
        </p>
      </div>

      <p className="text-gray-400 font-medium text-[13px] font-Roboto mb-4">
        {priceDetail?.detail}
      </p>
      <div className="flex flex-col gap-2 mb-2 pb-8 overflow-y-auto no-scrollbar h-[18rem]">
        {priceDetail?.features?.map((f: { feature: string }, index: number) => (
          <div className="flex items-center gap-4 " key={index}>
            <span className="w-5 h-5 rounded-full bg-yellow-yellow1 flex items-center justify-center">
              <BsCheckLg />
            </span>
            <p className="text-gray-500 font-medium text-sm font-Roboto">
              {f?.feature}
            </p>
          </div>
        ))}
      </div>

      <Button
        label={btnLabel}
        isYellowBtn={isYellowBtn}
        isDisabled={btnLabel === "Coming Soon..."}
        onClick={() =>
          navigate("/purchase", {
            state: {
              title: priceDetail.title,
              plan: priceDetail.plan,
              oneTimePrice: priceDetail.oneTimePrice,
              annualPrice: priceDetail.annualPrice,
            },
          })
        }
      />
    </div>
  );
};

export default PriceCard;
