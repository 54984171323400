type INfcCardProps = {
  img: { front: string; back: string };
  label: string;
  desc: string;
};
const NfcCard = (props: INfcCardProps) => {
  const {
    img: { front, back },
    label,
    desc,
  } = props;
  return (
    <div className="flex items-center flex-col gap-3 ">
      <div className="flip-card relative w-full h-40 md:h-20 lg:h-26 lg:h-36 flex justify-center items-center">
        <div className="flip-card-inner">
          <div className="flip-card-front h-full absolute z-[2] inset-0 mx-auto flex justify-center items-center">
            <img className="border border-white" src={front} alt="Front" />
          </div>
          <div className="flip-card-back h-full absolute inset-0 mx-auto flex justify-center items-center">
            <img className="border border-white" src={back} alt="Back" />
          </div>
        </div>
      </div>
      <h4 className="text-center text-black text-xl mt-5 font-semibold font-Kumbh">
        {label}
      </h4>
      <div className="justify-center items-center w-full gap-2.5 hidden">
        {label === "Platinum" ? (
          <>
            <div className="w-6 h-6 gradientGray rounded-full border" />
            <div className="w-6 h-6 bg-black-black1 rounded-full" />
            <div className="w-6 h-6 gradientYellow rounded-full" />
          </>
        ) : (
          <>
            {label === "Gold" ? (
              <>
                <div className="w-6 h-6 bg-black-black1 rounded-full" />
                <div className="w-6 h-6 gradientYellow rounded-full" />
              </>
            ) : (
              <>
                <div className="w-6 h-6 bg-white rounded-full border" />
                <div className="w-6 h-6 bg-black-black1 rounded-full" />
              </>
            )}
          </>
        )}
      </div>
      <p className="text-center w-3/4 text-base font-light font-Roboto">
        {desc}
      </p>
    </div>
  );
};

export default NfcCard;
