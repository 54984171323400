import React from "react";
import CustomTitle from "./CustomTitle";
import ReviewInput from "./ReviewInput";
import { IFormValues, IPaymentDetails } from "../../types/Purchase";

interface IReviewProps {
  values: IFormValues;
  paymentDetails: IPaymentDetails;
}
const Review = (props: IReviewProps) => {
  const { values, paymentDetails } = props;
  const {
    address1,
    address2,
    city,
    companyName,
    country,
    email,
    firstName,
    lastName,
    phone,
    state,
    zip,
  } = values;

  return (
    <div>
      <CustomTitle className="font-Kumbh mb-2 font-bold" title="Information" />
      <div className="grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <ReviewInput label="First name" value={firstName} />
          <ReviewInput label="Last name" value={lastName} />
        </div>
        <ReviewInput label="Company name (optional)" value={companyName} />
        <ReviewInput label="Email" value={email} />
        <ReviewInput label="Phone" value={phone} />
      </div>
      <CustomTitle
        className="font-Kumbh my-2 font-bold"
        title="Shipping address"
      />
      <div className="grid grid-cols-1 gap-4">
        <ReviewInput label="Address" value={address1} />
        <ReviewInput
          label="Apartment, suite, unit, etc. (optional)"
          value={address2}
        />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <ReviewInput label="City" value={city} />
          <ReviewInput label="State" value={state} />
          <ReviewInput label="ZIP" value={zip} />
        </div>
        <ReviewInput label="Country" value={country} />
      </div>
      <CustomTitle className="font-Kumbh my-2 font-bold" title="Payment type" />
      <ReviewInput label="Card Name" value={paymentDetails?.cardName} />
      <ReviewInput label="Card Number" value={paymentDetails?.cardNumber} />
    </div>
  );
};

export default Review;
