import "../../../App.css";
import SilverCardFront from "../../../assets/icons/SilverCardFront.svg";

const Hero1 = () => {
  return (
    <div className="flex lg:flex-row flex-col-reverse mb-4 md:mb-20 gap-4 lg:gap-1 justify-center items-center lg:h-[calc(100vh-80px)]">
      <section className="flex-1 h-auto lg:h-full flex justify-center items-center lg:justify-end">
        <section className="flex flex-col justify-start items-start gap-[15px] animate-hero1BgMotion w-[80%]">
          <h1 className=" text-white text-[20px] md:text-[30px] text-center lg:text-left font-semibold font-Kumbh">
            Share your contact details digitally with anyone, anywhere, anyway.
            No App? No Problem! Share your card through QR code or NFC.
          </h1>
          <p className=" text-neutral-500 text-base font-normal font-Roboto">
            Use your InTouch digital business card to let anyone know who you
            are - On the go.
          </p>
          <div className="justify-end items-start gap-[15px] inline-flex">
            <div className="px-5 py-2 bg-amber-300 rounded-[49px] justify-center items-center gap-2.5 flex">
              <button className="text-center text-black text-[13px] font-bold font-Inter">
                Create Your Card
              </button>
            </div>
            <div className="px-5 py-2 rounded-[56px] border border-white justify-center items-center gap-2.5 flex">
              <button className="text-center text-white text-[13px] font-bold font-Inter">
                For Teams
              </button>
            </div>
          </div>
        </section>
      </section>
      <section className="card1 lg:mr-[10%] flex-1 p-20 lg:p-0 lg:h-full w-full flex justify-center items-center animate-hero1BgMotion bg-no-repeat bg-center bg-cover">
        <img
          src={SilverCardFront}
          alt="Business Card"
          className={`h-20 w-48 md:h-auto md:w-auto animate-silverCardRotate`}
          id="businessCard"
        />
      </section>
    </div>
  );
};

export default Hero1;
