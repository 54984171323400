import { useMemo } from "react";
import { routePaths } from "../../../utils/constants";
import NfcCard from "./NfcCard";
import "./NfcCards.css";
import PlasticCardFront from "../../../assets/images/PlasticCard.png";
import SilverCardFront from "../../../assets/icons/SilverCardFront.svg";
import GoldCardFront from "../../../assets/svg/woodenCard.svg";
import PlasticCardBack from "../../../assets/images/PlasticCardBack.png";
import GoldCardBack from "../../../assets/images/GoldCardBack.png";
import SilverCardBack from "../../../assets/images/SilverCardBack.png";
import Slider from "react-slick";

const NfcCards = () => {
  const cards = useMemo(
    () => [
      {
        label: "Plastic",
        img: { front: PlasticCardFront, back: PlasticCardBack },
        desc: "Shipped with your InTouch NFC card with logo and QR code",
      },
      {
        label: "Wooden",
        img: { front: GoldCardFront, back: GoldCardBack },
        desc: "Shipped with your InTouch NFC card with logo, name, contact number and QR code",
      },
      {
        label: "Metal",
        img: { front: SilverCardFront, back: SilverCardBack },
        desc: "Shipped with your InTouch NFC card with Customised logo, name, contact number, company Name and QR code",
      },
    ],
    []
  );
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
  };
  return (
    <section
      className="w-full h-auto 2xl:h-[70vh] bg-white flex-col justify-start  items-center gap-4 md:gap-28 inline-flex pt-4 pb-12"
      id={routePaths.card}
    >
      <div className=" justify-center items-center flex pt-4">
        <h3 className="text-center text-black text-[32px] font-extrabold font-Kumbh uppercase tracking-[3px]">
          Types of NFC Cards
        </h3>
      </div>
      <div className="lg:w-[60%] hidden w-1/2 sm:w-3/4 md:grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8">
        {cards.map((card, index) => (
          <NfcCard {...card} key={index} />
        ))}
      </div>

      <div className="relative block md:hidden w-full h-full">
        <Slider {...settings}>
          {cards.map((card, index) => (
            <NfcCard {...card} key={index} />
          ))}
        </Slider>
        <div className="absolute w-3 h-full glowSideLeft"></div>
        <div className="absolute w-3 h-full glowSideRight"></div>
      </div>
    </section>
  );
};

export default NfcCards;
