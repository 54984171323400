import React from "react";
import TickIcon from "../../assets/images/tick.png";
import CopyIcon from "../../assets/icons/copy-to-board.svg";
import CustomTitle from "./CustomTitle";
import { useNavigate } from "react-router-dom";
interface IPaymentSuccessModalProps {
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const PaymentSuccessModal = (props: IPaymentSuccessModalProps) => {
  const { setShowSuccessModal } = props;
  const navigate = useNavigate();

  const handleComplete = () => {
    setShowSuccessModal(false);
    navigate("/");
  };
  return (
    <section className="fixed top-0 left-0 right-0 bottom-0 w-screen h-screen flex justify-center items-center bg-black-black1/70">
      <div className="rounded-md bg-white flex flex-col justify-center items-center gap-2 p-4 w-[80vw] md:w-[30vw] lg:w-[25vw]">
        <button className="self-end" onClick={() => setShowSuccessModal(false)}>
          x
        </button>
        <img alt="" src={TickIcon} className="w-[40px] h-[40px] object-cover" />
        <CustomTitle title="Order Complete!" className="font-Kumbh" />
        <div className="flex gap-2 items-center">
          <span className="text-sm text-green-green3 font-light">Ref no :</span>
          <span className="text-sm text-green-green3 font-semibold">
            7584HDJA
          </span>
          <button className="w-6 h-6 rounded-full flex items-center justify-center bg-gray-gray1">
            <img alt="" src={CopyIcon} className="w-4 h-4 object-contain" />
          </button>
        </div>
        <p className="text-center text-xs font-normal w-[90%]">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nemo
          doloremque libero cumque deleniti, modi pariatur minus aspernatur
          dolorem expedita asperiores.
        </p>
        <button
          className="w-max my-1 py-1 px-4 border border-black-black1 font-semibold text-sm rounded-lg"
          onClick={handleComplete}
        >
          Go Back
        </button>
      </div>
    </section>
  );
};

export default PaymentSuccessModal;
