import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CouponsResponseData,
  ICard,
  ISelectedPricePlan,
} from "../../types/Purchase";

import CustomTitle from "./CustomTitle";
import CardTileIcon from "./CardTileIcon";
import { fetchCouponDetails } from "../../services/Coupons";

interface IPaymentDetailsStep2Props {
  card: ICard;
  plans: ISelectedPricePlan[];
  currentStep: number;
  appliedDiscuntList: any;
  setAppliedDiscountList: (e: any) => void;
  setTotalAmount: (e: any) => void;
  totalAmount: any;
}
const PaymentDetailsStep2 = (props: IPaymentDetailsStep2Props) => {
  const {
    card,
    plans,
    currentStep,
    appliedDiscuntList,
    setAppliedDiscountList,
    setTotalAmount,
    totalAmount,
  } = props;
  const shippingPrice = 2.5;
  const [discountInputVal, setDiscountInputVal] = useState("");
  const [couponsData, setCouponsData] = useState<CouponsResponseData[]>([]);
  const [overAllDiscountPrice, setOverAllDiscountPrice] = useState(0);

  const overAllPrice = useMemo(
    () =>
      plans.map((p) => p.quantity * p.price)?.reduce((acc, curr) => acc + curr),
    [plans]
  );

  const calculateDiscountValue = (
    percentage: number | string,
    price: number
  ) => {
    return price * (Number(percentage) / 100);
  };

  const handleApplyDiscount = () => {
    if (
      discountInputVal &&
      discountInputVal !== "" &&
      couponsData &&
      Array.isArray(couponsData) &&
      couponsData.length > 0
    ) {
      couponsData.forEach((details) => {
        const plan = details.plan;
        if (
          details.coupons &&
          Array.isArray(couponsData) &&
          details.coupons.length > 0
        ) {
          details.coupons.forEach((couponDetails) => {
            const data: any = { ...couponDetails };
            if (couponDetails.coupon === discountInputVal) {
              if (
                appliedDiscuntList &&
                appliedDiscuntList.filter((val: any) => val.plan === plan)
                  .length === 0
              ) {
                const curPlanDetails = plans.filter((val) => val.plan === plan);
                data.plan = plan;
                data.discountPrice = calculateDiscountValue(
                  couponDetails.percentage,
                  curPlanDetails[0].price
                );
                setAppliedDiscountList((prev: any) => [...prev, data]);
              } else {
                if (
                  appliedDiscuntList.filter((val: any) => val.plan === plan)
                    .length > 0
                ) {
                  const exsitingCoupon = appliedDiscuntList.filter(
                    (val: any) => val.plan === plan
                  );
                  if (exsitingCoupon[0].percentage < couponDetails.percentage) {
                    let clonedCouponData = [...appliedDiscuntList];
                    const index = clonedCouponData.findIndex(
                      (val: any) => val.plan === plan
                    );
                    clonedCouponData.slice(index, 1);
                    const curPlanDetails = plans.filter(
                      (val) => val.plan === plan
                    );
                    data.plan = plan;
                    data.discountPrice = calculateDiscountValue(
                      couponDetails.percentage,
                      curPlanDetails[0].price
                    );
                    clonedCouponData[index] = data;
                    setAppliedDiscountList(clonedCouponData);
                  }
                }
              }
            }
          });
        }
      });
    }
  };

  const calculateTotalPrice = () => {
    if (
      appliedDiscuntList &&
      Array.isArray(appliedDiscuntList) &&
      appliedDiscuntList.length > 0
    ) {
      const overAllDiscountPrice = appliedDiscuntList
        .map((val) => val.discountPrice)
        .reduce((acc, curr) => acc + curr);
      setOverAllDiscountPrice(overAllDiscountPrice);
      setTotalAmount(overAllPrice - overAllDiscountPrice);
    }
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [appliedDiscuntList]);

  useEffect(() => {
    const plansArray = plans.map((plan) => plan.plan);
    fetchCouponDetails({
      title: card.title,
      plans: plansArray,
      setCouponsData,
    });
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <CardTileIcon id={card.id} />
          <CustomTitle title={card.name} className="text-xs" />
        </div>
        <CustomTitle
          title={`
          ${card.currency} ${overAllPrice?.toFixed(2)}
          `}
          className="text-xs"
        />
      </div>
      {plans.map((plan: ISelectedPricePlan, index: number) => (
        <div key={index} className="my-1 border-b pb-1">
          <div className="flex gap-2">
            <span className="text-gray-gray2 font-Roboto font-light text-[11px] flex items-center">
              Plan: <p className="ml-1 font-semibold">{plan?.plan}</p>
            </span>
            <span className="text-gray-gray2 font-Roboto font-light text-[11px] flex items-center">
              Material: <p className="ml-1 font-semibold">{plan?.material}</p>
            </span>
            <span className="text-gray-gray2 font-Roboto font-light text-[11px] flex items-center">
              Color: <p className="ml-1 font-semibold">{plan?.color}</p>
            </span>
          </div>
          <p className="text-xs font-normal">Quantity - {plan?.quantity}</p>
          {appliedDiscuntList &&
            appliedDiscuntList.length > 0 &&
            appliedDiscuntList
              .filter((val: any) => val.plan === plan?.plan)
              .map((detail: any) => {
                return (
                  <div className="flex items-center justify-between">
                    <p className="text-xs font-normal">{`${detail.coupon} - ${detail.percentage}%`}</p>
                    <p className="text-xs font-normal">
                      {detail.discountPrice}
                    </p>
                  </div>
                );
              })}
        </div>
      ))}
      <div>
        <div className="hidden items-center justify-between text-gray-gray2 mb-1 text-sm">
          <p>Category xxx</p>
          <p className="text-right">$0.00</p>
        </div>
        <div className="flex items-center justify-between text-gray-gray2 mb-1 text-sm">
          <p>Subtotal</p>
          <p className="text-right">$20.00</p>
        </div>
        <div className=" items-center justify-between text-gray-gray2 mb-1 text-sm hidden">
          <p>Shipping</p>
          <p className="text-right">{`$${shippingPrice?.toFixed(2)}`}</p>
        </div>
        <div className="flex items-center justify-between text-gray-gray2 mb-1 text-sm">
          <p>Tax</p>
          <p className="text-right">$0.00</p>
        </div>
      </div>
      <hr className="my-2" />
      {currentStep === 2 && (
        <div className="flex items-center gap-2 justify-between">
          <input
            className="w-full pl-4 border rounded-sm text-xs py-2 outline-none placeholder:text-green-green1 text-green-green1"
            placeholder="Coupen/Discount"
            value={discountInputVal}
            onChange={(e) => setDiscountInputVal(e.target.value)}
          />
          <button
            type="button"
            className="py-1 px-4 border border-black-black1 font-semibold text-sm rounded-lg"
            onClick={handleApplyDiscount}
          >
            Apply
          </button>
        </div>
      )}
      <hr className="my-2" />
      {appliedDiscuntList &&
        Array.isArray(appliedDiscuntList) &&
        appliedDiscuntList.length > 0 && (
          <div className="flex justify-between items-center gap-1 text-gray-gray2 mb-1 text-sm">
            <p>Subtotal</p>
            <p>{`${card.currency} ${overAllPrice?.toFixed(2)}`}</p>
          </div>
        )}
      {overAllDiscountPrice && (
        <div className="flex justify-between items-center gap-1 text-gray-gray2 mb-1 text-sm">
          <p>Discount</p>
          <p>{`-${card.currency} ${overAllDiscountPrice?.toFixed(2)}`}</p>
        </div>
      )}

      <div className="flex justify-between items-center gap-1">
        <CustomTitle title="Total" />
        {appliedDiscuntList &&
        Array.isArray(appliedDiscuntList) &&
        appliedDiscuntList.length > 0 ? (
          <CustomTitle title={`${card.currency} ${totalAmount?.toFixed(2)}`} />
        ) : (
          <CustomTitle title={`${card.currency} ${overAllPrice?.toFixed(2)}`} />
        )}
      </div>
    </div>
  );
};

export default PaymentDetailsStep2;
