import axios from "axios"
import { PlansType } from "../types/PricePlan"
import { PRICE_PLANS } from "./endpoints"



type fetchPlanProps = {
    setPlans: React.Dispatch<React.SetStateAction<PlansType>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}
export const fetchPlans = async ({ setPlans, setLoading }: fetchPlanProps) => {
    setLoading(true)
    try {
        const response = await axios.get(PRICE_PLANS)
        const plans = response.data?.length > 0 ? response.data[0] : {}
        setPlans(plans)
    } catch (error) {
        console.error('pricePlanError', error)
    } finally {
        setLoading(false)
    }
}