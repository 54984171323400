import React from 'react'
import PersonIcon from '../../assets/icons/icon-person.svg';
import PersonGroupIcon from '../../assets/icons/person-group.svg';
interface ICardTileIconProps {
    id: number,
    className?: string,
}
const CardTileIcon = (props: ICardTileIconProps) => {
    const { id, className } = props
    if (id === 2) return <img src={PersonGroupIcon} alt='' className={className} />

    return <img src={PersonIcon} alt='' className={className} />

}

export default CardTileIcon
