import axios, { AxiosResponse } from "axios";
import { COUPONS } from "./endpoints";
import { CouponsResponseData } from "../types/Purchase";

type fetchCouponProps = {
  title: string;
  plans: string[];
  setCouponsData: React.Dispatch<React.SetStateAction<CouponsResponseData[]>>;
};
export const fetchCouponDetails = async ({
  title,
  plans,
  setCouponsData,
}: fetchCouponProps) => {
  const results: CouponsResponseData[] = [];
  for (let i = 0; i < plans.length; i++) {
    const plan = plans[i];
    try {
      const response: AxiosResponse<CouponsResponseData[]> = await axios.get(
        `${COUPONS}/${title}/${plan}`
      );
      results.push(response.data[0]);
    } catch (error) {
      console.error("PurchaseDetailsError", error);
    }
  }

  setCouponsData(results);
};
