type ButtonProps = {
  label?: string;
  isYellowBtn?: boolean;
  onClick?: () => void;
  isDisabled?: boolean;
};
const Button = (props: ButtonProps) => {
  const { label, isYellowBtn, onClick, isDisabled } = props;
  return (
    <div className="w-[calc(100%-12px)] absolute bottom-4 left-0 right-0 mx-auto">
      <button
        disabled={isDisabled}
        className={`w-full p-2 font-medium font-Inter rounded-2xl ${
          isYellowBtn ? "border-none bg-yellow-yellow1" : "border bg-white"
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};

export default Button;
