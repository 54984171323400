import { useEffect, useRef, useState, useMemo } from "react";

const Advertisement = () => {
  const timerRef = useRef<any>();
  const ads = useMemo(
    () => [
      {
        desc: "Get 10% off use code INTOUCH(over $XXX)",
        bg: "#374D7C",
        color: "#ffffff",
      },
      {
        desc: "Get 15% off use code INTOUCHNOW",
        bg: "#46EDC8",
        color: "#000000",
      },
      { desc: "Free trial for 6 months", bg: "#E5DE87", color: "#000000" },
    ],
    []
  );
  const [activeAddIndex, setActiveAddIndex] = useState(0);
  useEffect(() => {
    timerRef.current = setInterval(() => {
      setActiveAddIndex((prev) => (prev === ads.length - 1 ? 0 : prev + 1));
    }, 3000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);
  const activeAdd = useMemo(() => ads[activeAddIndex], [activeAddIndex]);
  return (
    <div
      className={`w-full h-8 flex justify-center items-center bg-[${activeAdd.bg}] sticky top-0 left-0 right-0 z-50`}
    >
      {/* <p className={`text-[#000000] text-[#ffffff] bg-[#374D7C] bg-[#46EDC8] bg-[#E5DE87]`}>{activeAdd.desc}</p> */}
      <p className={`text-[${activeAdd.color}] text-sm`}>{activeAdd.desc}</p>
    </div>
  );
};

export default Advertisement;
